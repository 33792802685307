import React, { useEffect, useContext } from 'react'
import './home.css'
import LangContext from '../../context/LangContext'
import classLogo from  '../../images/image2.jpg'
import { Link } from 'react-router-dom'
import apprenticeLogo from '../../images/apprenticeship-v2.jpg'
import TrainingPartnerForm from '../../assets/Compliance Training Partner Fillable Application.pdf'

const Home = () => {
    const { langData } = useContext(LangContext);

    return (
        <div className='w-75 mx-auto pb-5'>
            <div className='home-cluster my-5 d-flex align-items-center justify-content-between'>
                <div className='d-grid text-left pr-5'>
                    <b className='h1 mb-5 w-50'>
                        {langData.home.apprentice}
                    </b>
                    <p className='pr-5 mr-5 mb-5'>
                        {langData.home.apprentice_desc_1}
                    </p>
                    <img className='home-image mobile' src={apprenticeLogo} />
                    <p className='pr-5 mr-5 mb-5'>
                        {langData.home.apprentice_desc_2}
                    </p>
                    <p className='pr-5 mr-5 mb-5'>
                        {langData.home.apprentice_desc_3}
                    </p>
                    {/* <a href="mailto:info@acteducate.com?subject=Apprenticeship Inquiry"> */}
                    <a href={TrainingPartnerForm} target="_blank">
                        <button
                            className='home-button wider'>
                            {langData.home.apprentice_button}
                        </button>
                    </a>
                </div>
                <img className='home-image' src={apprenticeLogo} />
            </div>
            <div className='home-cluster my-5'>
                <div className='h1 testimonials-header'>
                    <b>{langData.home.testimonial_header}</b>
                </div>
                <div className='testimonials d-flex align-items-center justify-content-between'>
                    <div className="mx-3 d-flex flex-column w-75">
                        <i className='mb-3'>“{langData.home.superior_testimonial}”</i>
                        <p className='fw-bold'>– Superior Fire Protection, Inc.</p>
                    </div> 
                    <div className="mx-3 d-flex flex-column w-75">
                        <i className='mb-3'>“{langData.home.rainer_testimonial}”</i>
                        <p className='fw-bold'>– The Rainier Group</p>
                    </div> 
                    <div className="mx-3 d-flex flex-column w-75">
                        <i className='mb-3'>“{langData.home.reliable_testimonial}”</i>
                        <p className='fw-bold'>– Reliable Fire & Security Solutions</p>
                    </div> 
                </div>
            </div>
            <div className='home-cluster mb-5 d-flex align-items-center justify-content-between'>
                <img className='home-image' src={classLogo} />
                <div className='d-grid text-left home-cluster flipped my-1'>
                    <b className='h1 mb-5 w-50'>
                        {langData.home.cont_edu}
                    </b>
                    <p className='mb-5'>
                        {langData.home.cont_edu_desc_1}
                    </p>
                    <img className='home-image mobile' src={classLogo} />
                    <p className='mb-5'>
                        {langData.home.cont_edu_desc_2}
                    </p>
                    <Link to="/courses">
                        <button className='home-button wider' onClick={() => window.scrollTo(0,0)}>
                            {langData.home.cont_edu_button}
                        </button>
                    </Link>
                </div>
            </div>
            {/* <div className="block" id="home-intro">
                <div id="overlay">
                    <div id="intro">
                        <p>{langData.home.mission_1}</p>
                        <br />
                        <p>{langData.home.mission_2}<a href="mailto:info@acteducate.com">info@acteducate.com</a></p>
                    </div>
                </div>
            </div>
            <div className="block" id="big-three-intro">
                <strong>{langData.home.big_three}</strong>
                <p>{langData.home.big_three_desc}</p>
            </div>
            <div className="block" id="big-three">
                <div className="row">
                    <div className="col-3 centered-content">
                        <img className="mb-2" src={calFireCirtLogo} />
                        <h2 className="mb-2">{langData.home.cert_management}</h2>
                        <div className="mx-4">
                            <p className="mb-2">{langData.home.cert_management_desc_1}</p>
                            <p className="mb-2">{langData.home.cert_management_desc_2}</p>
                            <p className="mb-2">{langData.home.cert_management_desc_3}</p>
                            <p className="mb-2">{langData.home.cert_management_desc_4}</p>
                        </div>
                    </div>
                    <div className="col-3 centered-content">
                        <img className="mb-2" src={classLogo} />
                        <h2 className="mb-2">{langData.home.edu_courses}</h2>
                        <div className="mx-4">
                            <p className="mb-2">{langData.home.edu_courses_desc_1}</p>
                            <p className="mb-2">{langData.home.edu_courses_desc_2}</p>
                            <p className="mb-2">{langData.home.edu_courses_desc_3}</p>
                            <p className="mb-2">{langData.home.edu_courses_desc_4}</p>
                        </div>
                    </div>
                    <div className="col-3 centered-content">
                        <img className="mb-2" src={apprenticeLogo} />
                        <h2 className="mb-2">{langData.home.apprentice_training}</h2>
                        <div className="mx-4">
                            <p className="mb-2">{langData.home.apprentice_training_desc_1}</p>
                            <p className="mb-2">{langData.home.apprentice_training_desc_2}</p>
                            <p className="mb-2">{langData.home.apprentice_training_desc_3}</p>
                        </div>
                    </div>
                </div>
            </div> 
        */}
        </div>
    )
}

export default Home