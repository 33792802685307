import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Popup from 'reactjs-popup';

import { UserInfo, CertList, Enrollments } from '../exportcomponents/PopupExports';
import CorrectPendingIndv from "./SuperCorrectionForms";
import SuperEditNonsub from './SuperEditNonsub';
import ViewCEUSubmits from './ViewCEUSubmits';
import AddCertificates from '../components/AddCertificate';

import UserContext from '../context/UserContext';
import SuperContext from '../context/SuperContext';
import LangContext from '../context/LangContext';
import { toast } from 'react-toastify';
import { ChangeClassification } from '../exportcomponents/FormExports';
import { SuperEnrollUser, SuperEditUser, SuperManageTokens, SuperCreateCustomTokens } from '../exportcomponents/ApprenticeExports';
import { collapsedStyle, isEmployee } from '../exportcomponents/StyleExports'
import UnpaidAccount from './UnpaidAccount';
import SuperResetPassword from '../components/SuperResetPassword';
import loadingWheel from '../images/loadingwheel.gif';
import { EnrollSingle } from '../admincomponents/EnrollComponents';
import AdminChange from '../components/AdminChange';
import AdminAdd from '../components/AdminAdd';
import AdminList from './AdminList';
import { timestampConverter } from '../exportcomponents/TimeStampConverter';
import { writeExcel } from '../services/excel';
import DeactivateNotes from './DeactivateNotes';

const AccountHistory = ({ classification, onLastClicked, activeDisplay }) => {
    const navigate = useNavigate();
    const { userData } = useContext(UserContext);
    const { superData, setSuperData } = useContext(SuperContext);
    const { langData } = useContext(LangContext);
    const [accountHistory, setAccountHistory] = useState([]);
    const [activeUsersSearch, setActiveUsersSearch] = useState("")
    const [activeHistorySearch, setActiveHistorySearch] = useState("")
    const [activeUsers, setActiveUsers] = useState([]);
    const [admins, setAdmins] = useState([])
    const [nonSubscribers, setNonSubscribers] = useState([]);
    const [validIds, setValidIds] = useState([]);
    const [isAdmin, setIsAdmin] = useState([])
    const [buttonDisable, setButtonDisable] = useState(false);
    const [nonSubButtonDisable, setNonSubButtonDisable] = useState(false);
    const [activeSubTab, setActiveSubTab] = useState(true);
    const [historyTab, setHistoryTab] = useState(false);
    const [nonSubTab, setNonSubTab] = useState(false);
    const [loadingLearnerData, setLoadingLearnerData] = useState('loading');
    const [adminSelected, setAdminSelected] = useState("All Accounts");
    const [reactivateClass, setReactivateClass] = useState(null)
    const [reactivateAdmin, setReactivateAdmin] = useState(null)
    const [activateIndividual, setActivateIndividual] = useState(false)
    const [preserveTokens, setPreserveTokens] = useState(false)
    const [selectedQuote, setSelectedQuote] = useState('')
    const [selectedHistory, setSelectedHistory] = useState('')
    const [selectedActive, setSelectedActive] = useState('')

    const moveToHistory = async (user, deactivation_note='') => {
        setButtonDisable(true);
        setTimeout(() => { setButtonDisable(false) }, 1500);
        const { email, id: account_id, admin_email } = user
        const confirmed = await window.confirm(`Are you sure you'd like to deactivate ${email}'s account?`)
        if (!confirmed) return
        console.log("splits: \n\n" + account_id + "\n\t" + admin_email);
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/account/history/${account_id}`, {
                admin_email: admin_email,
                deactivation_note
            });
        } catch (err) {
            // console.log(err);
        }

        await asyncSetupAccountHistory()
        return
        // navigate('/');
        // return navigate('/super');
    }

    const historyRowColor = (classification, admin_email) => {

        if (classification == 'null' && !JSON.parse(admin_email)) return 'darkTurquoise'

        switch (classification.split(' ')[0]) {
            case 'act_apprentice':
                return 'cadetBlue'
            case 'null':
                return 'rosyBrown'
            case null:
                return 'rosyBrown'
            case '':
                return 'rosyBrown'
            default:
                return JSON.parse(admin_email) ? 'darkKhaki' : 'darkSeaGreen'
        }
    }

    const reactivateAccount = async (account_id, old_class, old_admin, email, last_name, first_name) => {
        setButtonDisable(true);
        setTimeout(() => { setButtonDisable(false) }, 3000);
        if (activateIndividual == true & reactivateClass == 'act_apprentice') return toast.error("Cannot reactivate individuals as an ACT-Apprentice, please choose another classification")
        axios.post(`${process.env.REACT_APP_API_URL}/account/reactivate/${account_id}`, {
            email,
            old_class,
            old_admin,
            last_name,
            first_name,
            new_class: reactivateClass,
            preserve_tokens: preserveTokens,
            new_admin: activateIndividual ? null : reactivateAdmin,
        }).then(async () => {
            if (activateIndividual === false) try {
                await axios.put(`${process.env.REACT_APP_API_URL}/account/adminchange/${account_id}/${reactivateAdmin}`, { admin_change: old_admin != reactivateAdmin, from_history: true });
            } catch (admin_change_error) {
                console.error("Admin Change Failed: ", admin_change_error)
            }
        }).catch((err) => console.error(err))

        const active_user_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/activesubs`)
        setActiveUsers(active_user_data.data)
        toast.success("Account Reactivated");

        const history = await axios.get(`${process.env.REACT_APP_API_URL}/account/history`);
        console.log("HISTORY USER DATA: ", active_user_data)
        setAccountHistory(history.data);

        const non_sub_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/non-activesubs`)
        setNonSubscribers(non_sub_data.data)
    }

    const reactivateAdminAccounts = async (e) => {
        setButtonDisable(true);
        setTimeout(() => { setButtonDisable(false) }, 3000);
        await axios.post(`${process.env.REACT_APP_API_URL}/account/admin/reactivate/${e.target.value}`);

        const active_user_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/activesubs`)
        setActiveUsers(active_user_data.data)
        toast.success("Account Reactivated");

        const history = await axios.get(`${process.env.REACT_APP_API_URL}/account/history`);
        setAccountHistory(history.data);

        const non_sub_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/non-activesubs`)
        setNonSubscribers(non_sub_data.data)
    }

    const deleteFromHistory = async (account_id, name) => {
        const confirmedDelete = await window.confirm(`Are you sure you'd like to permanently delete ${name}'s account?`)
        if (!confirmedDelete) return
        console.log("Deleting account id: ", account_id)
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/account/user/${account_id}`)
            toast.success('Account Deleted')
            loadHistory()
        } catch (err) {
            console.error("An issue occurred deleting this account", err)
            toast.error("Something Went Wrong")
        }
    }

    const loadAdminIds = async () => {
        const admin_ids = await axios.get(`${process.env.REACT_APP_API_URL}/account/inactive/admins`)
        // console.log(admin_ids.data)
        setIsAdmin(admin_ids.data)
    }

    const loadAdmins = async () => {
        const admin_list = await axios.get(`${process.env.REACT_APP_API_URL}/account/admins`)
        // console.log(admin_list.data)
        setAdmins(admin_list.data)
    }

    const activeUsersList = !activeUsersSearch
        ? activeUsers
            .sort(({ first_name: a_first, last_name: a_last }, { first_name: b_first, last_name: b_last }) => {
                const a = `${a_first.toLowerCase()} ${a_last.toLowerCase()}`
                const b = `${b_first.toLowerCase()} ${b_last.toLowerCase()}`
                return a > b
                    ? 1
                    : a < b ? -1 : 0
            })
        : activeUsers
            .filter(ind =>
                ind.email.toString().toLowerCase().includes(activeUsersSearch.toLocaleLowerCase()) ||
                (ind.first_name.toString() + " " + ind.last_name.toString()).toLowerCase().includes(activeUsersSearch.toLocaleLowerCase()) ||
                ind.shorthand.toString().toLowerCase().includes(activeUsersSearch.toLocaleLowerCase())
            )
            .sort(({ first_name: a_first, last_name: a_last }, { first_name: b_first, last_name: b_last }) => {
                const a = `${a_first.toLowerCase()} ${a_last.toLowerCase()}`
                const b = `${b_first.toLowerCase()} ${b_last.toLowerCase()}`
                return a > b
                    ? 1
                    : a < b ? -1 : 0
            });

    const activeHistoryList = !activeHistorySearch
        ? accountHistory
            .sort(({ first_name: a_first, last_name: a_last }, { first_name: b_first, last_name: b_last }) => {
                const a = `${a_first?.toLowerCase()} ${a_last?.toLowerCase()}`
                const b = `${b_first?.toLowerCase()} ${b_last?.toLowerCase()}`
                return a > b
                    ? 1
                    : a < b ? -1 : 0
            })
        : accountHistory
            .filter(hist =>
                hist.email?.toString().toLowerCase().includes(activeHistorySearch.toLocaleLowerCase()) ||
                (hist.first_name?.toString() + " " + hist.last_name?.toString()).toLowerCase().includes(activeHistorySearch.toLocaleLowerCase()) ||
                hist.shorthand?.toString().toLowerCase().includes(activeHistorySearch.toLocaleLowerCase())
            )
            .sort(({ first_name: a_first, last_name: a_last }, { first_name: b_first, last_name: b_last }) => {
                const a = `${a_first} ${a_last}`
                const b = `${b_first} ${b_last}`
                return a > b
                    ? 1
                    : a < b ? -1 : 0
            });

    // const timestampConverter = (timestamp) => {
    //     if (!timestamp) return 'Bodily'
    //     console.log("MR MCGILL!! ", timestamp)
    //     let a = new Date(timestamp)
    //     let months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    //     let year = a.getFullYear();
    //     let month = months[a.getMonth()];
    //     let date = a.getDate();

    //     let time = month + ' ' + date + ', ' + year;

    //     return time
    // }

    function classFormatter(raw_class) {
        try {
            const split_class = raw_class.split('act_')
            if (split_class.length > 1) {
                return 'ACT Apprentice'
            }
            return raw_class.charAt(0).toUpperCase() + raw_class.slice(1);
        } catch (err) {
            // console.log('Error in class formatter\n', err);
            return 'No Classification'
        }
    }

    const loadHistory = async () => {
        let historyData = await axios.get(`${process.env.REACT_APP_API_URL}/account/history`);
        console.log("History Data: ", historyData.data)

        setAccountHistory(historyData.data);
    }

    const downloadActiveSubs = async () => {
        console.log("download active subsss: ", activeUsers)
        let user_rows = []
        for (const user of activeUsers) {
            let { id, first_name, last_name, email, address_1, address_2, city, state, zip, password, learner_id, account_id, alt_classification: classification, classification: absorb_classification, ...user_row } = user
            user_row = {
                name: `${first_name} ${last_name}`,
                email,
                address: `${address_1}${address_2 ? ` ${address_2}` : ''}`,
                city,
                state,
                zip,
                classification,
                absorb_classification,
                ...user_row
            }
            user_rows.push(user_row)
        }
        writeExcel(user_rows, 'active_subs')
    }

    async function asyncSetupAccountHistory() {
        const active_user_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/activesubs`)
        // const active_user_data = await axios.get(`${process.env.REACT_APP_API_URL}/2.0/fitter`) // TODO: 2.0 BABY!
        const active_subs = active_user_data.data
        console.log("active_subs:", active_subs)
        setActiveUsers(active_subs)

        const non_sub_data = await axios.get(`${process.env.REACT_APP_API_URL}/account/non-activesubs`)
        setNonSubscribers(non_sub_data.data)

        loadHistory();

        let allAccounts = []
        const all_card_ids = await axios.get(`${process.env.REACT_APP_API_URL}/calfire/all/cards`)
        for (let i = 0; i < all_card_ids.data.length; i++) {
            allAccounts.push(all_card_ids.data[i].account_id)
        }

        loadAdmins()
        loadAdminIds()
        setValidIds(allAccounts)
    }

    useEffect(() => {
        asyncSetupAccountHistory()
    }, []);

    return (
        <div>
            {activeDisplay == 'active_subs' && (
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-5 ml-2'>
                        <div className='d-flex justify-content-start align-items-center'>
                            <h3 className='h3 text-secondary'>{langData.super_functionality.active_subs}</h3>
                            {/* <div>
                                {loadingLearnerData === 'loading' ? (
                                    <>
                                        <img alt=" " src={loadingWheel} className="loading-absorb-gif mx-4"/>
                                        <strong>
                                            Loading Learner Data
                                        </strong>
                                    </>
                                ) : (
                                    <>
                                    {loadingLearnerData === 'error' &&
                                        <>
                                            <strong>
                                                Failed to load CEU's
                                            </strong>
                                        </>
                                    }
                                    </>
                                )}
                            </div> */}
                        </div>
                        <div className='d-flex justify-content-end'>
                            <div className="input-group rounded mr-2">
                                <select
                                    className="form-select h-100 mr-2"
                                    aria-label="Select Admin"
                                    name="admin"
                                    onChange={(e) => setAdminSelected(e.target.value)}
                                >
                                    <option>All Accounts</option>
                                    {admins && admins.map((admin, i) => {
                                        return (
                                            <option key={i} value={admin.admin_email}>{admin.business_name + " - " + admin.business_number}</option>
                                        );
                                    })}
                                </select>
                                <input
                                    onChange={(e) => setActiveUsersSearch(e.target.value)}
                                    type="search"
                                    className="form-control rounded"
                                    placeholder={langData.placeholders.search}
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                    value={activeUsersSearch}
                                />
                                <span className="input-group-text border-0" id="search-addon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                            </div>
                            <div className="tab-title apprentice rounded">
                                <h1>{(activeUsersList || []).length}</h1>
                            </div>
                            <span onClick={(e) => asyncSetupAccountHistory()} className="input-group-text rounded border-0 ml-3">
                                <svg width="1.3rem" height="1.3rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g><path d="M17.91 14c-.478 2.833-2.943 5-5.91 5-3.308 0-6-2.692-6-6s2.692-6 6-6h2.172l-2.086 2.086L13.5 10.5 18 6l-4.5-4.5-1.414 1.414L14.172 5H12c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.08 0 7.438-3.055 7.93-7h-2.02z" /></g>
                                </svg>
                            </span>
                            <span onClick={(e) => downloadActiveSubs()} className="input-group-text rounded border-0 ml-3">
                                <svg width="1.2rem" height="1.2rem" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                    <g><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 242.7-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7 288 32zM64 352c-35.3 0-64 28.7-64 64l0 32c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-32c0-35.3-28.7-64-64-64l-101.5 0-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352 64 352zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    {/* <div 
                        className={collapsedStyle(!activeSubTab)}
                        // type="button" 
                        // data-toggle="collapse" 
                        // data-target="#collapseExample" 
                        // aria-expanded="false" 
                        // aria-controls="collapseExample"
                        // onClick={() => {setActiveSubTab(!activeSubTab); onLastClicked("active_subs");}}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        <div>
                            <strong className="pending-text">{langData.super_functionality.active_subs}</strong>	
                        </div>
                        <div className="tab-title">
                            <h1>{(activeUsersList || []).length}</h1>
                        </div>
                    </div> */}
                    <div>
                        {/* <div className="d-flex justify-content-between">
                            <div className="input-group rounded m-3">
                                <div className="d-flex mt-2">
                                    <input
                                        onChange={(e) => setActiveUsersSearch(e.target.value)}
                                        type="search"
                                        className="form-control rounded"
                                        placeholder={langData.placeholders.search}
                                        aria-label="Search"
                                        aria-describedby="search-addon"
                                        value={activeUsersSearch}
                                    />
                                    <span className="input-group-text border-0" id="search-addon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                        </svg>
                                    </span>
                                </div>
                                <div className="select-admin d-flex mt-2">
                                    <select 
                                        className="form-select" 
                                        aria-label="Select Admin" 
                                        name="admin" 
                                        onChange={(e) => setAdminSelected(e.target.value)}
                                    >
                                        <option>All Accounts</option>
                                            {admins && admins.map((admin, i) => {
                                                return (
                                                    <option key={i} value={admin.admin_email}>{admin.admin_email + " - " + admin.email}</option>
                                                );
                                            })}
                                    </select>
                                </div>
                            </div>
                            <div className="loading-absorb">
                                {loadingLearnerData === 'loading' ? (
                                    <>
                                        <strong style={{ color: 'white', fontSize: '20px'}}>
                                            Loading Learner Data
                                        </strong>
                                        <img alt=" " src={loadingWheel} className="loading-absorb-gif ml-4"/>
                                    </>
                                ) : (
                                    <>
                                    {loadingLearnerData === 'error' &&
                                        <>
                                            <strong style={{ color: 'white', fontSize: '20px'}}>
                                                Failed to load CEU's
                                            </strong>
                                        </>
                                    }
                                    </>
                                )}
                            </div>
                        </div> */}
                        <div className="color-code-table mt-2 rounded">
                            <strong className="color-coded-text">Color Codes: </strong>
                            <div className="d-flex">
                                <div className="text-center mr-2">
                                    <div style={{ backgroundColor: 'darkSeaGreen', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                                        <strong style={{ color: '#363636' }}>Individual</strong>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <div style={{ backgroundColor: 'white', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                                        <strong style={{ color: '#363636' }}>Employee</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='min-vh-50'>
                            {(activeUsersList || []).map((individual, i) => {
                                return (
                                    <div>
                                        {adminSelected.includes(individual.admin_email) || adminSelected.includes("All Accounts") ? (
                                            <div
                                                key={i}
                                                onClick={() => setSelectedActive(individual)}
                                                className={`card card-body mt-3 expandable ${selectedActive.id == individual.id && 'select-highlight'}`}
                                                style={{ color: isEmployee(individual.admin_email) ? "white" : "black", background: isEmployee(individual.admin_email) }}>

                                                {((individual.paid === false && !individual.admin_email) || (individual.admin_paid === false)) && (<UnpaidAccount account_id={individual.id} is_employee={individual.admin_paid === false} />)}
                                                <div className='row mt-0'>
                                                    <div className='col-sm'>
                                                        <strong style={{ color: 'black' }}>{langData.super_functionality.name}</strong>
                                                        <p style={{ color: 'black' }}>
                                                            {individual.first_name + " " + individual.last_name}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm'>
                                                        <strong style={{ color: 'black' }}>{langData.super_functionality.email}</strong>
                                                        <p style={{ color: 'black' }}>
                                                            {individual.email}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm'>
                                                        <strong style={{ color: 'black' }}>Tokens:</strong>
                                                        <p style={{ color: 'black' }}>
                                                            {individual.tokens ? individual.tokens : <i>No Tokens</i>}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm'>
                                                        <strong style={{ color: 'black' }}>Classification:</strong>
                                                        <p style={{ color: 'black' }}>
                                                            {individual.classification || individual.alt_classification}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm'>
                                                        <strong style={{ color: 'black' }}>Admin:</strong>
                                                        <p style={{ color: 'black' }}>
                                                            {!individual.admin_email ? 'Individual' : `${individual.admin_email} | ${individual.business_name}`}
                                                        </p>
                                                    </div>
                                                    {/* <div className='col-sm'>
                                                    <strong style={{ color: 'black' }}>CEU Hours:</strong>
                                                    <p style={{ color: 'black' }}>
                                                        {individual.ceus_completed || <i>No CEU's Earned</i>}
                                                    </p>
                                                </div>
                                                <div className='col-sm'>
                                                    <strong style={{ color: 'black' }}>Next CEU Submit Date:</strong>
                                                    <p style={{ color: 'black' }}>
                                                        {timestampConverter(individual.submittal_due)}
                                                    </p>
                                                </div> */}
                                                </div>
                                                <div className={`row mt-3 ${selectedActive.id !== individual.id ? 'hide-text' : 'text-body'}`}>
                                                    <div className='col-sm'>
                                                        <strong>Card #:</strong>
                                                        <p>
                                                            {individual.card_number || 'No Card #'}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm'>
                                                        <strong>Date First Listed:</strong>
                                                        <p>
                                                            {individual.date_issued_string || 'No Issued Date'}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm'>
                                                        <strong>Current Sticker #:</strong>
                                                        <p>
                                                            {individual.current_sticker || 'No Sticker #'}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm'>
                                                        <strong>CEU Submittal Period:</strong>
                                                        <p>
                                                            {individual.submittal_period_string || 'No Submittal Period'}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm'>
                                                        <strong>CEU's towards Next Submittal:</strong>
                                                        <p>
                                                            {individual.ceus_completed || "No CEU's"}
                                                        </p>
                                                    </div>
                                                    {/* <div className='col-sm'>
                                                    <strong>Admin:</strong>
                                                    <p>
                                                        {!individual.admin_email ? 'Individual' : `${individual.admin_email} | ${individual.business_name}`}
                                                    </p>
                                                </div>
                                                <div className='col-sm'>
                                                    <strong>Next CEU Submit Date:</strong>
                                                    <p>
                                                        {timestampConverter(individual.submittal_due)}
                                                    </p>
                                                </div> */}
                                                </div>
                                                {/* <div key={i} className="card pl-3 py-3 mt-3 d-flex flex-row align-items-center" style={{ color: isEmployee(individual.admin_email) ? "white" : "black" , background: isEmployee(individual.admin_email) }}>
                                                <div className="active-sub-info">
                                                    <div>
                                                        {individual.paid === false && <UnpaidAccount account_id={individual.id}/>}
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                            <strong style={{ color: 'black' }}>{langData.super_functionality.name}</strong>
                                                        <p style={{ color: 'black' }} className="sub-info">
                                                            {individual.first_name + " " + individual.last_name}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <strong style={{ color: 'black' }}>{langData.super_functionality.email}</strong>
                                                        <p style={{ color: 'black' }} className="sub-info">
                                                        {individual.email}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <strong style={{ color: 'black' }}>Tokens:</strong>
                                                        <p style={{ color: 'black' }} className="sub-info">
                                                        {individual.tokens ? individual.tokens : <i>No Tokens</i>}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <strong style={{ color: 'black' }}>CEU Hours:</strong>
                                                        <p style={{ color: 'black' }} className="sub-info">
                                                        {individual.ceus_completed || <i>No CEU's Earned</i>}
                                                        </p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <strong style={{ color: 'black' }}>Classification:</strong>
                                                        <p style={{ color: 'black' }} className="sub-info">
                                                        {individual.classification}
                                                        </p>
                                                    </div>
                                                    {individual.admin_email &&
                                                        <div className="d-flex justify-content-between">
                                                            <strong style={{ color: 'black' }}>Admin:</strong>
                                                            <p style={{ color: 'black' }} className="sub-info">
                                                            {individual.admin_email} | {individual.business_name}
                                                            </p>
                                                        </div>
                                                    }
                                                    {validIds.includes(individual.id) && individual.admin_email &&
                                                        <div className="d-flex justify-content-between">
                                                            <strong style={{ color: 'black' }}>Next CEU Submit Date:</strong>
                                                            <p style={{ color: 'black' }} className="sub-info">
                                                            {timestampConverter(individual.submittal_due)}
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                                <div style={{ textAlign: 'initial' }} className="mt-2">
                                                    
                                                </div>
                                            </div>	 */}
                                            </div>
                                        ) : (null)}
                                    </div>
                                )
                            })}
                        </div>
                        <div className='d-flex flex-wrap justify-content-center position-sticky bottom-0'>
                            <div className='opaque p-3 w-75 d-flex flex-wrap justify-content-center'>
                                <Popup
                                    modal
                                    trigger={<button className="text-nowrap m-1">{langData.super_functionality.info}</button>}
                                >
                                    <UserInfo
                                        id={selectedActive.id}
                                    />
                                </Popup>
                                <Popup
                                    modal
                                    trigger={<button className="text-nowrap m-1" value={selectedActive.id}>Edit Info</button>}
                                >
                                    <CorrectPendingIndv
                                        id={selectedActive.id}
                                        liveRefresh={() => asyncSetupAccountHistory()}
                                    />
                                </Popup>
                                {/* <Popup
                                    modal
                                    trigger={<button className="text-nowrap m-1">{langData.super_functionality.certs}</button>}
                                >
                                    <CertList
                                        id={selectedActive.id}
                                    />
                                </Popup> */}
                                <Popup
                                    modal
                                    trigger={<button className="text-nowrap m-1">Enrollments</button>}
                                >
                                    <Enrollments
                                        account_id={selectedActive.id}
                                        account={selectedActive}
                                    />
                                </Popup>
                                <Popup
                                    modal
                                    trigger={<button className="text-nowrap m-1">Enroll</button>}
                                >
                                    <EnrollSingle
                                        super_enrollment={selectedActive}
                                    />
                                </Popup>
                                {/* <Popup
                                    modal
                                    trigger={<button className="text-nowrap m-1">Add Certification</button>}
                                >
                                    <AddCertificates
                                        user={selectedActive.id}
                                    />
                                </Popup> */}
                                {/* {classification.includes(selectedActive.id) ? (
                                    <Popup
                                        modal
                                        trigger={<button disabled className="btn text-nowrap btn-danger m-1">Change Classification (In-Progress)</button>}
                                    >
                                        <ChangeClassification
                                            id={selectedActive.id}
                                            old_class={selectedActive.classification}
                                            email={selectedActive.email}
                                        />
                                    </Popup>
                                ) : (
                                    <Popup
                                        modal
                                        trigger={<button className="btn text-nowrap btn-primary m-1">Change Classification</button>}
                                    >
                                        <ChangeClassification
                                            id={selectedActive.id}
                                            old_class={selectedActive.classification}
                                            email={selectedActive.email}
                                        />
                                    </Popup>
                                )} */}
                                {!validIds.includes(selectedActive.id) ? (
                                    <Popup
                                        modal
                                        trigger={<button className="text-nowrap m-1">Cal Fire Card</button>}
                                    >
                                        <SuperEnrollUser
                                            id={selectedActive.id}
                                            classification={selectedActive.classification}
                                        />
                                    </Popup>
                                ) : (
                                    <Popup
                                        modal
                                        trigger={<button className="text-nowrap m-1">Edit Cal Fire Card</button>}
                                    >
                                        <SuperEditUser
                                            id={selectedActive.id}
                                            classification={selectedActive.classification}
                                            email={selectedActive.email}
                                        />
                                    </Popup>
                                )}
                                {/* <Popup
                                    modal
                                    trigger={<button className="btn text-nowrap btn-primary m-1">View All CEU's</button>}
                                >
                                    <ViewCEUSubmits 
                                        id={selectedActive.id}
                                        hasCalCard={!validIds.includes(selectedActive.id)}
                                    />
                                </Popup> */}
                                <Popup
                                    modal
                                    trigger={<button className="text-nowrap m-1">Manage Tokens</button>}
                                >
                                    <SuperManageTokens
                                        id={selectedActive.id}
                                        tokens={selectedActive.tokens}
                                        learner_id={selectedActive.learner_id}
                                        liveRefresh={() => asyncSetupAccountHistory()}
                                    />
                                </Popup>
                                {!selectedActive.admin_email ? (
                                    <Popup
                                        modal
                                        trigger={<button className="text-nowrap m-1">Custom Priced Tokens</button>}
                                    >
                                        <SuperCreateCustomTokens
                                            id={selectedActive.id}
                                            tokens={selectedActive.tokens}
                                        />
                                    </Popup>
                                ) : (null)}
                                <Popup
                                    modal
                                    trigger={<button className="text-nowrap m-1">Change Password</button>}
                                >
                                    <SuperResetPassword
                                        email={selectedActive.email}
                                    />
                                </Popup>
                                {selectedActive.admin_email ? (
                                    <Popup
                                        modal
                                        trigger={<button className='text-nowrap m-1'>Update Employment</button>}>
                                        <AdminChange
                                            employee={selectedActive}
                                            liveRefresh={() => asyncSetupAccountHistory()}
                                        />
                                    </Popup>
                                ) : (
                                    <Popup
                                        modal
                                        trigger={<button className="text-nowrap m-1">{langData.settings.add_ID}</button>}
                                    >
                                        <AdminAdd
                                            individual={selectedActive}
                                            liveRefresh={() => asyncSetupAccountHistory()}
                                        />
                                    </Popup>
                                )}
                                <Popup
                                    modal
                                    trigger={<button
                                        className="text-nowrap m-1"
                                        // onClick={moveToHistory}
                                        value={`${selectedActive.email},${selectedActive.id},${selectedActive.admin_email}`}
                                        disabled={buttonDisable}
                                    >
                                        Deactivate
                                    </button>}>
                                    <DeactivateNotes user={selectedActive} deactivateCallback={(e, notes) => moveToHistory(selectedActive, notes)} />
                                </Popup>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(activeDisplay == 'account_history' &&
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-5 ml-2'>
                        <h3 className='h3 text-secondary'>Account History</h3>
                        <div className='d-flex justify-content-end'>
                            <div className="input-group rounded mr-2">
                                <input
                                    onChange={(e) => setActiveHistorySearch(e.target.value)}
                                    type="search"
                                    className="form-control rounded"
                                    placeholder={langData.placeholders.search}
                                    aria-label="Search"
                                    aria-describedby="search-addon"
                                    value={activeHistorySearch}
                                />
                                <span className="input-group-text border-0" id="search-addon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                            </div>
                            <div className="tab-title apprentice rounded">
                                <h1>{accountHistory.length}</h1>
                            </div>
                            <span onClick={(e) => asyncSetupAccountHistory()} className="input-group-text rounded border-0 ml-3">
                                <svg width="1.3rem" height="1.3rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g><path d="M17.91 14c-.478 2.833-2.943 5-5.91 5-3.308 0-6-2.692-6-6s2.692-6 6-6h2.172l-2.086 2.086L13.5 10.5 18 6l-4.5-4.5-1.414 1.414L14.172 5H12c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.08 0 7.438-3.055 7.93-7h-2.02z" /></g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    {/* <div className="card mt-3">
                    <div 
                        className={collapsedStyle(!historyTab)}
                        // type="button" 
                        // data-toggle="collapse" 
                        // data-target="#historyCollapse" 
                        // aria-expanded="false" 
                        // aria-controls="historyCollapse"
                        // onClick={() => {setHistoryTab(!historyTab); onLastClicked("account_history");}}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                    >
                        <div>
                            <strong className="pending-text">Account History</strong>
                        </div>
                        <div className="tab-title">
                            <h1>{accountHistory.length}</h1>
                        </div>
                    </div> */}
                    <div className="color-code-table mt-2 rounded">
                        <strong className="color-coded-text">Color Codes: </strong>
                        <div className="d-flex">
                            <div className="text-center mr-2">
                                <div style={{ backgroundColor: 'rosyBrown', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                                    <strong style={{ color: '#363636' }}>Admin</strong>
                                </div>
                            </div>
                            <div className="text-center mr-2">
                                <div style={{ backgroundColor: 'cadetBlue', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                                    <strong style={{ color: '#363636' }}>Apprentice</strong>
                                </div>
                            </div>
                            <div className="text-center mr-2">
                                <div style={{ backgroundColor: 'darkKhaki', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                                    <strong style={{ color: '#363636' }}>Employee</strong>
                                </div>
                            </div>
                            <div className="text-center mr-2">
                                <div style={{ backgroundColor: 'darkSeaGreen', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                                    <strong style={{ color: '#363636' }}>Individual</strong>
                                </div>
                            </div>
                            <div className="text-center">
                                <div style={{ backgroundColor: 'darkTurquoise', height: '23px', minWidth: '130px', borderRadius: '20px' }}>
                                    <strong style={{ color: '#363636' }}>Non Sub</strong>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='min-vh-50'>
                        {(activeHistoryList || []).map((quote, i) => {
                            return (
                                <div key={i} className={`card card-body mt-3 ${selectedHistory.id == quote.id && 'select-highlight'}`} onClick={() => setSelectedHistory(quote)} style={{ backgroundColor: historyRowColor(quote.classification, quote.admin_email) }}>
                                    <div className='row'>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                {langData.super_functionality.name}
                                            </strong>
                                            <p>
                                                {quote.first_name + " " + quote.last_name}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                {langData.super_functionality.email}
                                            </strong>
                                            <p>
                                                {quote.email}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                Admin:
                                            </strong>
                                            <p>
                                                {quote.admin_email}
                                            </p>
                                        </div>
                                    </div>

                                    {/* <div key={i} className="card mt-3 p-3 d-flex flex-row justify-content-between" style={{ backgroundColor: historyRowColor(quote.classification, quote.admin_email) }}>
                                    <div className="quote-information">
                                        <div className="d-flex">
                                            <strong className="text-left">{langData.super_functionality.name}</strong>
                                            <p className="ml-2">{quote.first_name + " " + quote.last_name}</p>
                                        </div>
                                        <div className="d-flex">
                                            <strong className="text-left">{langData.pending_quotes.email}</strong>
                                            <p className="ml-2">{quote.email}</p>
                                        </div>
                                        <div className="d-flex">
                                            <strong className="text-left">Admin:</strong>
                                            <p className="ml-2">{quote.admin_email}</p>
                                        </div>
                                    </div>
                                    {/* <div className="d-inline-flex">
                                        <Popup
                                            modal
                                            trigger={<button className="btn btn-warning m-1 space">Info</button>}
                                        >
                                            <UserInfo
                                                id={quote.id}
                                            />
                                        </Popup>
                                        {isAdmin && isAdmin.includes(quote.id) ? (
                                            <div>
                                                <button 
                                                    className="btn btn-success m-1 ml-2" 
                                                    onClick={reactivateAdminAccounts} 
                                                    value={quote.id}
                                                    disabled={buttonDisable}
                                                >
                                                        Reactivate Admin
                                                </button>
                                            </div>
                                        ): (
                                            <Popup
                                                modal
                                                onOpen={() => { setReactivateClass(quote.classification); setReactivateAdmin(quote.admin_email); setActivateIndividual(!quote.admin_email || quote.admin_email == 'null');}}
                                                onClose={() => setReactivateClass(null)}
                                                trigger={<button className="btn btn-success m-1 ml-2" >Reactivate</button>}>
                                                <div className='form-background' style={{ width: '370px' }}>
                                                    <div className='mb-2 d-flex justify-content-between'>
                                                        <b>Selected:</b>
                                                        <p>{quote.email}</p>
                                                    </div>
                                                    <b>Classification</b>
                                                    <select 
                                                        className='form-select rounded mb-2'
                                                        value={reactivateClass}
                                                        onChange={(e) => setReactivateClass(e.target.value)}>
                                                        {['act_apprentice', 'fitter'].map(option => {
                                                            return <option
                                                                disabled={option == 'act_apprentice' & activateIndividual}
                                                                value={option}>{option}
                                                            </option>
                                                        })}
                                                        {/* {!quote.classification | quote.classification == 'null' | quote.classification == '' && 
                                                            <option
                                                                disabled
                                                                value={'null'}>
                                                                Admin
                                                            </option>
                                                        }
                                                    </select>
                                                    <b className={activateIndividual && 'striked'}>Admin</b>
                                                    <select
                                                        disabled={activateIndividual}
                                                        value={reactivateAdmin}
                                                        onChange={(e) => setReactivateAdmin(e.target.value)}
                                                        className='form-select rounded'>
                                                        {!activateIndividual 
                                                        ? admins.map(admin => { 
                                                            return <option
                                                                value={admin.business_number}>
                                                                {admin.business_name}
                                                            </option>
                                                        }) :
                                                        <option>
                                                            Individual
                                                        </option>
                                                        }
                                                    </select>
                                                    <div className='d-flex flex-row justify-content-between mt-3'>
                                                        <button 
                                                            className="btn btn-success" 
                                                            onClick={() => reactivateAccount(quote.id, quote.classification, quote.admin_email, quote.email, quote.last_name, quote.first_name)}
                                                            disabled={buttonDisable}
                                                            >
                                                                Reactivate
                                                        </button>
                                                        <div>
                                                            <div className='d-flex flex-row align-items-center justify-content-end'>
                                                                <b className='mr-2'>
                                                                    Reactivate as Individual
                                                                </b>
                                                                <input
                                                                    className='reactivate-checkbox'
                                                                    // onChange={(e) => console.log("Vote for me to be your boyfriend", e.target.checked)}
                                                                    checked={activateIndividual}
                                                                    onChange={(e) => setActivateIndividual(e.target.checked)}
                                                                    type="checkbox" />
                                                            </div>
                                                            <div className='d-flex flex-row align-items-center justify-content-end'>
                                                                <b className='mr-2'>
                                                                    Preserve Tokens
                                                                </b>
                                                                <input
                                                                    className='reactivate-checkbox'
                                                                    // onChange={(e) => console.log("Vote for me to be your boyfriend", e.target.checked)}
                                                                    onChange={(e) => setPreserveTokens(e.target.checked)}
                                                                    type="checkbox" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popup>
                                        )}
                                        <button className='btn btn-danger m-1 ml-2' onClick={() => deleteFromHistory(quote.id, quote.first_name + ' ' + quote.last_name)}>Permanently Delete</button>
                                    </div>
                                </div> */}
                                </div>
                            )
                        })}
                    </div>
                    <div className='d-flex justify-content-center position-sticky bottom-0'>
                        <div className='opaque p-3 w-75 d-flex justify-content-evenly align-items-center'>
                            <Popup
                                modal
                                trigger={<button className="text-nowrap m-1">Info</button>}
                            >
                                <UserInfo
                                    id={selectedHistory.id}
                                />
                            </Popup>
                            {isAdmin && isAdmin.includes(selectedHistory.id) ? (
                                <div>
                                    <button
                                        className="text-nowrap m-1"
                                        onClick={reactivateAdminAccounts}
                                        value={selectedHistory.id}
                                        disabled={buttonDisable}
                                    >
                                        Reactivate Admin
                                    </button>
                                </div>
                            ) : (
                                <Popup
                                    modal
                                    onOpen={() => { setReactivateClass(selectedHistory.classification); setReactivateAdmin(selectedHistory.admin_email); setActivateIndividual(!selectedHistory.admin_email || selectedHistory.admin_email == 'null'); }}
                                    onClose={() => setReactivateClass(null)}
                                    trigger={<button className="text-nowrap m-1" >Reactivate</button>}>
                                    <div className='form-background'>
                                        <h2 className="h2 mb-2">Reactivate</h2>
                                        <div className='mb-2 d-flex justify-content-between'>
                                            <b>Reactivating:</b>
                                            <p>{selectedHistory.email}</p>
                                        </div>
                                        <div className='mb-2 d-flex flex-column'>
                                            <b>Reason for Deactivation:</b>
                                            <p>{selectedHistory.deactivation_note || 'No reason listed...'}</p>
                                        </div>
                                        <label>Classification</label>
                                        <select
                                            className='form-select rounded mb-2 w-100'
                                            value={reactivateClass}
                                            onChange={(e) => setReactivateClass(e.target.value)}>
                                            {['act_apprentice', 'fitter'].map(option => {
                                                return <option
                                                    disabled={option == 'act_apprentice' & activateIndividual}
                                                    value={option}>{option}
                                                </option>
                                            })}
                                            {!selectedHistory.classification | selectedHistory.classification == 'null' | selectedHistory.classification == '' &&
                                                <option
                                                    disabled
                                                    value={'null'}>
                                                    Admin
                                                </option>
                                            }
                                        </select>
                                        <label className={activateIndividual && 'striked'}>Admin</label>
                                        <select
                                            disabled={activateIndividual}
                                            value={reactivateAdmin}
                                            onChange={(e) => setReactivateAdmin(e.target.value)}
                                            className='form-select rounded w-100'>
                                            {!activateIndividual
                                                ? admins.map(admin => {
                                                    return <option
                                                        value={admin.business_number}>
                                                        {admin.business_name}
                                                    </option>
                                                }) :
                                                <option>
                                                    Individual
                                                </option>
                                            }
                                        </select>
                                        <div className='d-flex flex-row-reverse justify-content-end my-3'>
                                            <div>
                                                <div className='d-flex flex-row-reverse align-items-center justify-content-end'>
                                                    <b className='ml-2 pb-1'>
                                                        Reactivate as Individual
                                                    </b>
                                                    <input
                                                        className='reactivate-checkbox'
                                                        // onChange={(e) => console.log("Vote for me to be your boyfriend", e.target.checked)}
                                                        checked={activateIndividual}
                                                        onChange={(e) => setActivateIndividual(e.target.checked)}
                                                        type="checkbox" />
                                                </div>
                                                <div className='d-flex flex-row-reverse align-items-center justify-content-end'>
                                                    <b className='ml-2 pb-1'>
                                                        Preserve Tokens
                                                    </b>
                                                    <input
                                                        className='reactivate-checkbox'
                                                        // onChange={(e) => console.log("Vote for me to be your boyfriend", e.target.checked)}
                                                        onChange={(e) => setPreserveTokens(e.target.checked)}
                                                        type="checkbox" />
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="w-100"
                                            onClick={() => reactivateAccount(selectedHistory.id, selectedHistory.classification, selectedHistory.admin_email, selectedHistory.email, selectedHistory.last_name, selectedHistory.first_name)}
                                            disabled={buttonDisable}
                                        >
                                            Reactivate
                                        </button>
                                    </div>
                                </Popup>
                            )}
                            <button className='m-1 text-nowrap' onClick={() => deleteFromHistory(selectedHistory.id, selectedHistory.first_name + ' ' + selectedHistory.last_name)}>Permanently Delete</button>
                        </div>
                    </div>
                </div>
            )}
            {activeDisplay == 'non_subs' && (
                <div>
                    <div className='d-flex align-items-center justify-content-between mt-5 ml-2'>
                        <h3 className='h3 text-secondary'>Non-Subscriber Accounts</h3>
                        <div className='d-flex justify-content-end'>
                            <div className="tab-title apprentice rounded">
                                <h1>{nonSubscribers.length}</h1>
                            </div>
                            <span onClick={(e) => asyncSetupAccountHistory()} className="input-group-text rounded border-0 ml-3">
                                <svg width="1.3rem" height="1.3rem" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g><path d="M17.91 14c-.478 2.833-2.943 5-5.91 5-3.308 0-6-2.692-6-6s2.692-6 6-6h2.172l-2.086 2.086L13.5 10.5 18 6l-4.5-4.5-1.414 1.414L14.172 5H12c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.08 0 7.438-3.055 7.93-7h-2.02z" /></g>
                                </svg>
                            </span>
                        </div>
                    </div>
                    {/* <div
                        className={collapsedStyle(!nonSubTab)}
                        // type="button" 
                        // data-toggle="collapse" 
                        // data-target="#nonsubCollapse" 
                        // aria-expanded="false" 
                        // aria-controls="nonsubCollapse"
                        // onClick={() => { setNonSubTab(!nonSubTab); onLastClicked("non_subs");}}
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                    >	
                        <div>
                            <strong className="pending-text">Non-Subscriber Accounts</strong>
                        </div>
                        <div className="tab-title">
                            <h1>{nonSubscribers.length}</h1>
                        </div>
                    </div> */}
                    <div className='min-vh-50'>
                        {nonSubscribers.map((quote, i) => {
                            return (
                                <div key={i} className={`card card-body mt-3 ${selectedQuote.id == quote.id && 'select-highlight'}`} onClick={() => setSelectedQuote(quote)}>
                                    <div className='row'>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                {langData.super_functionality.name}
                                            </strong>
                                            <p>
                                                {quote.first_name + " " + quote.last_name}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                {langData.super_functionality.email}
                                            </strong>
                                            <p>
                                                {quote.email}
                                            </p>
                                        </div>
                                        <div className='col-sm'>
                                            <strong className="mt-2">
                                                Tokens:
                                            </strong>
                                            <p>
                                                {quote.tokens}
                                            </p>
                                        </div>
                                    </div>
                                    {/* <div key={i} className="card mt-3 p-3">
                                        <div className="quote-information">
                                            <div className="d-flex">
                                                <strong className="text-left">{langData.super_functionality.name}</strong>
                                                <p className="ml-2">{selectedQuote.first_name + " " + selectedQuote.last_name}</p>
                                            </div>
                                            <div className="d-flex">
                                                <strong className="text-left">{langData.pending_quotes.email}</strong>
                                                <p className="ml-2">{selectedQuote.email}</p>
                                            </div>
                                            <div className="d-flex">
                                                <strong className="text-left">Tokens: </strong>
                                                <p className="ml-2">{selectedQuote.tokens}</p>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'initial' }} className="m-2">
                                            
                                        </div>    
                                    </div> */}
                                </div>
                            )
                        })}
                    </div>
                    <div className='d-flex justify-content-center position-sticky bottom-0'>
                        <div className='opaque p-3 w-75 d-flex justify-content-evenly align-items-center'>
                            <Popup
                                modal
                                trigger={<button className="text-nowrap m-1">Info</button>}
                            >
                                <UserInfo
                                    id={selectedQuote.id}
                                />
                            </Popup>
                            <Popup
                                modal
                                trigger={<button className="text-nowrap m-1" value={selectedQuote.id}>Make Corrections</button>}
                            >
                                <SuperEditNonsub
                                    id={selectedQuote.id}
                                />
                            </Popup>
                            <button
                                className="text-nowrap m-1"
                                onClick={(e) => moveToHistory(selectedQuote)}
                                // value={selectedQuote.id}
                                disabled={nonSubButtonDisable}
                            >
                                Deactivate
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AccountHistory
