import React, { useState, useEffect, useContext } from 'react';
import LangContext from '../context/LangContext';
import { toast } from 'react-toastify'
import axios from 'axios'

const SendCeuEmailUpdates = () => {
    const { langData: { send_ceu_updates } } = useContext(LangContext);

    async function startCeuEmailAutomation(test_mode) {
        try {
            toast.success('Fitter CEU progress emails started. Please allow time for all emails to send.')
            await recurseCeuEmails(0, test_mode)
        } catch (err) {
            console.error(err)
            toast.error("Something went wrong, please try again in several minutes.")
        }
    }

    async function recurseCeuEmails(last_index_sent, test_mode) {
        // switch from recursion to a single api call
        const single_result = await axios.post(`${process.env.REACT_APP_API_URL}/automation/start-email-ceu-progress?test_mode=${test_mode}&last_index_sent=${last_index_sent}`)
        console.log(single_result)
        return
        // if (last_index_sent > 10) return
        axios.post(`${process.env.REACT_APP_API_URL}/automation/start-email-ceu-progress?test_mode=${test_mode}&last_index_sent=${last_index_sent}`)
            .then(({ data: { last_index_sent: new_index_sent }}) => {
                console.log("last last: ", new_index_sent)
                if (!new_index_sent) return
                recurseCeuEmails(new_index_sent, test_mode)
            })
    }

    useEffect(() => {
        // is this necessary?
    }, []);

    return (
        <div className='form-background'>
            <h2 className="h2 mb-2">{send_ceu_updates.header}</h2>
            <button className="mt-2 w-100" onClick={() => startCeuEmailAutomation(false)}>{send_ceu_updates.submit_button}</button>
            <button className="mt-2 w-100" onClick={() => startCeuEmailAutomation(true)}>{send_ceu_updates.test_button}</button>
        </div>
    );
}

export default SendCeuEmailUpdates;