import React, { isValidElement, useState, useEffect, useContext } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { useStripe } from '@stripe/react-stripe-js';
import axios from "axios"
import { toast } from 'react-toastify'
import "../components/styles.css"
import "./super.css";
import { Validator } from '../exportcomponents/ValidatorExports';
import PrivacyPolicy from '../exportcomponents/LegalExports/LegalExports';
import TermsAndConditions from '../exportcomponents/TermsAndConditions';
import LangContext from '../context/LangContext';

const INTERVALS = { 
    month: {
        1: 'monthly',
        2: 'quarterly',
        3: 'biannually',
        4: 'annually'
    }, year: {
        1: 'annually'
    }
}

const CorrectPendingIndv = ({id, editorId, show_billing, liveRefresh=undefined}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const stripe = useStripe();
    const { langData } = useContext(LangContext);
    const [form, setForm] = useState({ classification: 'fitter' });
    const [subscriptionCost, setSubscriptionCost] = useState(45);
    const [buttonDisable, setButtonDisable] = useState({});
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [termsAndConds, setTermsAndConds] = useState(false);
    const [yesNo, setYesNo] = useState({ yes: false, no: true });
    const SUB_PRICE = { fitter: 45, apprentice: 35, trainee: 35, other: 25 };
    const ITV_PRICE = { monthly: 1, quarterly: 3, biannually: 6, annually: 12 };
	const [userInfo, setUserInfo] = useState({});

    const onChange = (e) => {
        // console.log(e.target.name);
        // console.log(e.target.value);
        // Classification is selected
        if (e.target.name == 'classification') {
            // Current selection is the default (no change)
            if (e.target.value == 'Select Account Type' || e.target.value == undefined) {
                // No usable data for form. billing frequency
                if (form.billing_frequency == 'Select Billing Frequency' || form.billing_frequency == undefined) {
                    setForm({ ...form, [e.target.name]: undefined });
                // Usable data in billing frequency
                } else {
                    setForm({ ...form, [e.target.name]: undefined });
                    setSubscriptionCost(SUB_PRICE[userInfo.classification] * ITV_PRICE[form.billing_frequency]);
                }
            // Current selection in classification has usable data
            } else {
                // Form has no usable billing frequency data
                if (form.billing_frequency == 'Select Billing Frequency' || form.billing_frequency == undefined) {
                    setForm({ ...form, [e.target.name]: e.target.value });
                    setSubscriptionCost(SUB_PRICE[e.target.value] * ITV_PRICE[userInfo.billing_frequency]);
                // Form has useful billing_frequency
                } else {
                    setForm({ ...form, [e.target.name]: e.target.value });
                    setSubscriptionCost(SUB_PRICE[e.target.value] * ITV_PRICE[form.billing_frequency]);
                }
            }
        // Billing Frequency is changed
        } else if (e.target.name == 'billing_frequency') {
            // Current Selection is the default (no change)
            if (e.target.value == 'Select Billing Frequency' || e.target.value == undefined) {
                // There's no useful form classification data
                if (form.classification == 'Select Account Type' || form.classification == undefined) {
                    setForm({ ...form, [e.target.name]: undefined });
                // There's useful daata in form.classification
                } else {
                    setForm({ ...form, [e.target.name]: undefined });
                    setSubscriptionCost(SUB_PRICE[form.classification] * ITV_PRICE[userInfo.billing_frequency]);
                }
            // Current selection in billing_frequency has useful data
            } else {
                // Form.classification has no useful data
                if (form.classification == 'Select Account Type' || form.classification == undefined) {
                    setForm({ ...form, [e.target.name]: e.target.value });
                    setSubscriptionCost(SUB_PRICE[userInfo.classification] * ITV_PRICE[e.target.value]);
                // There's useful information in form classification
                } else {
                    setForm({ ...form, [e.target.name]: e.target.value });
                    setSubscriptionCost(SUB_PRICE[form.classification] * ITV_PRICE[e.target.value]);
                }
            }
        } else {
            setForm({ ...form, [e.target.name]: e.target.value });
        }
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            // console.log(form);
            if (form.email) {
                const email_is_unique = await axios.post(`${process.env.REACT_APP_API_URL}/account/check-email-duplicate`, { email: form.email });
                // console.log(email_is_unique);
                if (email_is_unique.data) {
                    return toast.error(langData.toast.email_in_use);
                }
            }
            if (form.billing_frequency || form.classification) {
                // update billing stuff
                try {
                    let billing_obj = {
                        account_id: id,
                        classification: userInfo.classification || form.classification,
                        billing_frequency: form.billing_frequency ? form.billing_frequency : userInfo.billing_frequency,
                    }
                    // console.log(billing_obj)
                    await axios.put(`${process.env.REACT_APP_API_URL}/account/pending/billing`, billing_obj);
                } catch (err) {
                    // console.log(err);
                }
            }
            
            let loadedForm = {}

            for (const [key, value] of Object.entries(form)) {
                if (key !== 'classification' && key !== 'billing_frequency') { loadedForm = { ...loadedForm, [key]: value } }
            }
            console.log("loadedForm: ", loadedForm)
            // return
            await axios.put(`${process.env.REACT_APP_API_URL}/account/super/${id}`, loadedForm);
            // await axios.put(`${process.env.REACT_APP_API_URL}/2.0/user/info/${id}`, loadedForm); // TODO: 2.0 BABY!
            toast.success(langData.toast.profile_changes_success);
            if (liveRefresh()) await liveRefresh()
            // navigate("/")
            // console.log("looooooooooCATION: ", location.pathname)
            // navigate(location.pathname)

        } catch (err) {
            toast.error(err.message.data);
        }
    };

    async function asyncSetupSuperCorrect() {
        const quoteData = await axios.get(`${process.env.REACT_APP_API_URL}/account/user/${id}`);
        let interval, interval_count;

        try {
            const billingData = await axios.get(`${process.env.REACT_APP_API_URL}/account/billing/details/${id}`);
            interval = billingData.data.interval;
            interval_count = billingData.data.interval_count;

        } catch (err) {
            interval = 'month';
            interval_count = 1;
        }

		// console.log(quoteData.data)
        // console.log(userInfo)
        let loadedForm = {}

        for (const [key, value] of Object.entries(quoteData.data)) {
            if (key !== 'classification') { loadedForm = { ...loadedForm, [key]: value } }
        }
        // console.log({ ...quoteData.data, billing_frequency: INTERVALS[interval][interval_count] })
        setUserInfo({ ...quoteData.data, billing_frequency: INTERVALS[interval][interval_count] });
    }

	useEffect(() => {
		asyncSetupSuperCorrect()
	}, []);

    return (
        <div className="form-background popup-scrollable">
            <form onSubmit={submit} >
            <h2 className="h2 mb-3">Edit Profile</h2>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.email}</label>
                    </div>
                    <input
                        type="email"
                        className="mb-2"
                        name="email"
                        onChange={onChange}
                        placeholder={userInfo.email}
                    />
                </div>
                <div className="form-group">
                <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.first}</label>     
                    </div>
                    <input
                        type="first_name"
                        className="mb-2"
                        name="first_name"
                        onChange={onChange}
                        placeholder={userInfo.first_name}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.last}</label>
                    </div>
                    <input
                        type="last_name"
                        className="mb-2"
                        name="last_name"
                        onChange={onChange}
                        placeholder={userInfo.last_name}
                    />
                </div>
                <div className="form-group">
                <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.phone}</label>
                    </div>
                    <input
                        type="phone"
                        className="mb-2"
                        name="phone"
                        onChange={onChange}
                        placeholder={userInfo.phone}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.address_1}</label>
                    </div>
                    <input
                        type="address_1"
                        className="mb-2"
                        name="address_1"
                        onChange={onChange}
                        placeholder={userInfo.address_1}
                    />
                </div>
                <div className="form-group">
                    <div>
                        <label htmlFor="displayName">{langData.sign_up.address_2}</label>
                    </div>
                    <input
                        type="address_2"
                        className="mb-2"
                        name="address_2"
                        onChange={onChange}
                        placeholder={userInfo.address_2}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.city}</label>
                    </div>
                    <input
                        type="city"
                        className="mb-2"
                        name="city"
                        onChange={onChange}
                        placeholder={userInfo.city}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.state}</label>
                    </div>
                    <input
                        type="state"
                        className="mb-2"
                        name="state"
                        onChange={onChange}
                        placeholder={userInfo.state}
                    />
                </div>
                <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.zip}</label>
                    </div>
                    <input
                        type="zip"
                        className="mb-2"
                        name="zip"
                        onChange={onChange}
                        placeholder={userInfo.zip}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="displayName">{langData.sign_up.language}</label>
                    <select
                        className="form-select"
                        name="language"
                        aria-label="Default select example"
                        defaultValue={form.language}
                        onChange={onChange}
                    >
                        <option value="English">{langData.sign_up.english}</option>
                        <option value="Spanish">{langData.sign_up.spanish}</option>
                    </select>
                </div>
                {/* <div className="form-group">
                    <div className="d-flex justify-content-between">
                        <label htmlFor="email">{langData.sign_up.classification}</label>
                    </div>
                    <select
                        onChange={onChange}
                        name="classification"
                        className="form-select"
                        aria-label=".form-select-sm example">
                        <option selected>{langData.sign_up.select_account_type}</option>
                        <option value="fitter">CA Fire Sprinkler Fitter - 45$</option>
                        <option value="apprentice">CA Fire Sprinkler Apprentice - 35$</option>
                        <option value="trainee">CA Fire Sprinkler Trainee 35$</option>
                        <option value="other">Other - 25$</option>
                    </select>
                </div> */}
                {show_billing && (
                    <>
                        <strong className="correction header">Edit Billing</strong>
                        <div className="form-group">
                            <label htmlFor="email">{langData.sign_up.billing_frequency}</label>
                            <select
                                onChange={onChange}
                                name="billing_frequency"
                                className="form-select"
                                aria-label=".form-select-sm example">
                                <option selected>{langData.sign_up.billing_frequency_select}</option>
                                <option value="monthly">{langData.sign_up.monthly}</option>
                                <option value="quarterly">{langData.sign_up.quarterly}</option>
                                <option value="biannually">{langData.sign_up.bi_annually}</option>
                                <option value="annually">{langData.sign_up.annually}</option>
                            </select>
                        </div>
                        <div className="mt-2" style={{fontSize: '20px'}}>
                            {(form.classification != undefined || form.billing_frequency != undefined) ? (
                                <div>
                                    <strong>{langData.sign_up.selected_subs}</strong>
                                    {(form.classification || form.billing_frequency) ? (
                                        <p>{form.classification ? (form.classification.charAt(0).toUpperCase()) : (userInfo.classification.charAt(0).toUpperCase())}{form.classification ? (form.classification.slice(1)) : (userInfo.classification.slice(1))}</p>
                                    ) : (
                                        <p className="font-italic">{userInfo.classification}</p>    
                                    )}
                                    {(form.billing_frequency || form.classification) ? (
                                        <p>${subscriptionCost}.00 / {form.billing_frequency ? form.billing_frequency : userInfo.billing_frequency}</p>
                                    ) : (
                                        <p className="font-italic">{userInfo.billing_frequency}</p>    
                                    )}
                                </div>
                            ) : (
                                <div>
                                    <p className="correction italic">No Billing Changes Selected</p>
                                </div>
                            )}
                        </div>
                    </>
                )}
                <div>
                    <button
                        type="submit" 
                        className="btn btn-primary submit mt-4 w-100"
                        disabled={buttonDisable.submit}>
                        Save Changes
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CorrectPendingIndv
