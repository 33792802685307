import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../context/UserContext'
import axios from 'axios'
import LangContext from '../context/LangContext'
import '../components/styles.css'

const ApprenticeCourses = () => {
    const { userData } = useContext(UserContext)
    const { langData } = useContext(LangContext)
    const [courseList, setCourseList] = useState([])


    function formatCourseDescription(description) {
        if (!description) return <i>
            {langData.absorb_courses.no_description}
        </i>
        try { 
            return <p>{description.split(/[<>]/)[2].split("&nbsp;")[0]}</p>
        } catch (err) {
            return <p>{description}</p>
        }
    }

    async function setupApprenticeCourses() {
        try {
            const learner_data = await axios.get(
                `${process.env.REACT_APP_API_URL}/absorb/learner/${userData.userId}`
            );

            const apprentice_courses = await axios.get(
            //   `${process.env.REACT_APP_API_URL}/absorb/courses/d36c51e6-b2f6-4cb5-a1ef-fe4d7129016c?apprentice_courses=true`
              `${process.env.REACT_APP_API_URL}/absorb/courses/${learner_data.learner_id}?apprentice_courses=true`
            );

            console.log("IF THE EARTH GAVE WAY INTO PARADISE: \n", Object.keys(apprentice_courses.data[0]))
            const filtered_courses = apprentice_courses.data
                .filter(course => course.Status >= 0)
                .map(({ Id, Name, Score, Status, Progress, Description }) => ({
                    Id,
                    Name,
                    Score,
                    Status,
                    Progress,
                    Description,
                }))

            console.log("SOMEHOW YOU LOVE MEEEEE: \n", filtered_courses)
            setCourseList(filtered_courses)
            
        } catch (error) {
            console.error("Error loading apprentice courses")
        }
    }

    useEffect(() => {
        setupApprenticeCourses()
    }, [])
    return (
        <div className="absorb course-display mb-5">
            <div className='my-5 pt-5'>
                <h1 className='h1'>
                    {langData.absorb_courses.apprentice_courses}
                </h1>
            </div>
            {courseList.length == 0 
                ? ( 
                    <i className='my-5'>
                        {langData.absorb_courses.loading_courses}
                    </i> 
                ) : 
                courseList.map((course, i) => (
                    <div className="card text-left p-3 my-3">
                        <div className="card-body d-flex justify-content-between align-items-center">
                            <div className='w-75 pr-5'>
                                <h2 className="h2">{course.Name}</h2>
                                {formatCourseDescription(course.Description)}
                            </div>
                            <div className='w-25'>
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <b className="h5 mr-4">
                                        {langData.absorb_courses.progress}
                                    </b>
                                    <span className="pb-1">
                                        {course.Progress || 0}%
                                    </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <b className="h5 mr-5">
                                        {langData.absorb_courses.final_score}
                                    </b>
                                    <span className="pb-1">
                                        {course.Score || 0}%
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                )
            }
        </div>
    )
}

export default ApprenticeCourses